.editor-content {
  &.editor-content--white-links {
    a {
      @apply link-white-gray border-b border-white;
    }
  }

  &.cookie-bar p {
    @apply inline;
  }

  h1 {
    @apply typo-d-marge mb-3 mt-6;
  }

  h2 {
    @apply typo-d-homer mb-3 mt-4;
  }

  h3 {
    @apply typo-lovejoy mb-2 mt-4;
  }

  h4 {
    @apply typo-apu mb-2 mt-4;
  }

  h5 {
    @apply typo-edna mb-2 mt-3;
  }

  ol {
    @apply ml-5 list-decimal;

    li {
      @apply mb-2 pl-2;
    }
  }

  ul {
    li {
      @apply relative ml-4 mb-2;

      &:before {
        @apply absolute -ml-4 mr-2 text-gold-500;
        content: '\2022';
      }
    }
  }

  a,
  .a {
    @apply border-b border-transparent text-gold-500 transition duration-200 ease-in-out;

    &:hover {
      @apply border-current;
    }
  }

  p {
    @apply mb-3;
  }

  iframe {
    @apply max-w-full;
  }

  > *:first-child {
    @apply mt-0;
  }

  > *:last-child {
    @apply mb-0;
  }

  b,
  strong {
    /* font-weight: 700; */
    font-weight: 500;
  }

  img {
    width: auto;
  }

  table {
    @apply mx-0 table-auto;

    p {
      @apply mb-0;
    }
  }

  th {
    @apply bg-gold-700 text-white;
  }

  th,
  td {
    @apply px-6 py-3;
  }

  tr:nth-child(even) {
    @apply bg-gray-600;
  }
}
