.pc-print {
  display: none;
}

@media print {
  body {
    color: #000 !important;
    background: #fff !important;
  }
  .pc-cockpit {
    display: none !important;
  }

  .pc-print {
    display: block;
  }

  .cdk-overlay-container {
    display: none;
  }
}
