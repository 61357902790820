.mat-calendar {
  font-family: $font-base;
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background-color: var(--base-gold-200);
  }
}

.mat-calendar-body-in-range::before {
  background-color: var(--base-gold-100);
}

.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: var(--base-gold-200);
}
