.container {
  @screen lg {
    max-width: 110rem;
  }
}

.container-max {
  max-width: none;
}

.container-web {
  max-width: 72rem;
  @apply mx-auto;
}
