$mat-dialog-padding: 1.5rem;

.mat-mdc-dialog-container.mat-dialog-container {
  padding: 0 $mat-dialog-padding;
}

.mat-mdc-dialog-content.mat-dialog-content {
  display: block;
  margin: 0 (-$mat-dialog-padding);
  padding: $mat-dialog-padding;
  padding-bottom: 0;
}

.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-color: var(--base-black);
}
