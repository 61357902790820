@layer components {
  %link {
    @apply cursor-pointer transition-colors duration-200;
  }

  .link-gold {
    @extend %link;
    @apply text-gold-500 hover-hover:hover:text-gold-700;
  }

  .link-white-gold {
    @extend %link;
    @apply text-white hover-hover:hover:text-gold-500;
  }

  .link-black-gray {
    @extend %link;
    @apply text-black hover-hover:hover:text-gray-500;

    &.is-disabled {
      @apply cursor-default text-gray-400 hover-hover:hover:text-gray-400;
    }
  }

  .link-white-gray {
    @extend %link;
    @apply text-white hover-hover:hover:text-gray-400;
  }
}
