.float-label {
  @apply relative flex flex-col-reverse;

  label,
  input,
  textarea {
    @apply cursor-text;
    touch-action: manipulation;
    transition: all 0.2s;
  }

  input {
    @apply pt-5 pb-1;
  }

  textarea {
    @apply pt-6;
  }

  label {
    @apply absolute top-0 h-6 px-2 text-gray-500;
    text-align: left !important;
  }

  input,
  textarea {
    @apply appearance-none px-2 focus:outline-none;
    font-family: inherit;
  }

  input:placeholder-shown + label,
  textarea:placeholder-shown + label {
    @apply truncate;
    max-width: 90%;
    top: 0.6875rem;
  }

  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    @apply opacity-100;
  }

  input:not(:placeholder-shown) + label,
  textarea:not(:placeholder-shown) + label,
  input:focus + label,
  textarea:focus + label {
    @apply text-3xs text-gray-500;
    top: 0.25rem !important;
  }
}

::-webkit-input-placeholder {
  @apply opacity-0;
  transition: inherit;
}

.float-label-dark {
  label {
    @apply text-gray-500;
  }
  input:not(:placeholder-shown) + label,
  textarea:not(:placeholder-shown) + label,
  input:focus + label,
  textarea:focus + label {
    @apply text-gray-400;
  }
}
