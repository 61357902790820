$pseudo-shadow-size: 0.5rem;

.pseudo-shadow-bottom,
.pseudo-shadow-left,
.pseudo-shadow-right {
  @apply relative;

  &:after {
    @apply absolute transform from-gray-100;
    content: '';
  }
}

.pseudo-shadow-bottom {
  &:after {
    @apply inset-x-0 bottom-0 h-2 w-full translate-y-2 bg-gradient-to-b;
  }
}

.pseudo-shadow-left {
  &:after {
    @apply inset-y-0 left-0 h-full w-2 -translate-x-2 bg-gradient-to-l;
  }
}

.pseudo-shadow-right {
  &:after {
    @apply inset-y-0 right-0 h-full w-2 translate-x-2 bg-gradient-to-r;
  }
}
