.tr-hover {
  td {
    @apply transition-colors duration-300;
  }
}

.tr-scroll-arrow {
  @apply invisible opacity-0;
  transition: visibility 0.3s 0s, opacity 0.3s;
}

.tr-hover:hover {
  > td {
    @apply bg-gold-100;
  }
  .tr-scroll-arrow {
    @apply visible opacity-100;
    transition: visibility 0s, opacity 0.3s;
  }
}

.tr-highlight {
  > td {
    @apply bg-gold-100 transition-colors duration-1000;
  }
}

.tr-highlight-animate {
  animation: tr-highlight-animation 3s forwards;
}

.tr-rejected {
  > td {
    // prettier-ignore
    @apply bg-red-100 #{!important};
  }
}

@keyframes tr-highlight-animation {
  0% {
    @apply opacity-0 shadow-glow;
    transform: scale(1, 1);
  }
  10% {
    @apply opacity-0;
  }
  25% {
    @apply opacity-100;
  }
  50% {
    @apply opacity-100 shadow-glow;
    transform: scale(1, 1);
  }
  100% {
    @apply opacity-100 shadow-glow;
    transform: scale(1, 1);
  }
}
