.form-input,
.form-select,
.form-textarea {
  @apply block w-full appearance-none rounded-none rounded-t border-0 border-b py-3 px-2 text-inherit transition-colors duration-200 focus:shadow-none focus:ring-0;

  @extend .typo-otto;

  @apply border-gray-500 bg-gray-100 text-black placeholder-gray-500 focus:border-gold-500;
}

.form-input,
.form-select {
  @apply h-[3.0625rem];
}

.form-input-dark,
.form-textarea-dark,
.form-select-dark {
  @apply border-white bg-gray-600 text-white placeholder-gray-400;
}

.form-textarea {
  @apply leading-normal;
}

.form-checkbox,
.form-radio {
  @apply mt-0.5 h-5 w-5 cursor-pointer border-gray-400 bg-transparent text-gold-500 shadow-light transition-colors duration-200 focus:border-gold-300 focus:ring focus:ring-gold-100 focus:ring-opacity-50;

  + label {
    @apply cursor-pointer pl-2;

    a {
      @apply border-b border-transparent text-gold-500 transition duration-150 ease-in-out;

      &:hover {
        @apply border-current;
      }
    }
  }

  &:disabled {
    @apply opacity-50;
  }
}

.form-checkbox {
  @apply rounded;
}

.form-radio {
  @apply rounded-full;
}

.form-input-error,
.form-textarea-error,
.form-checkbox-error,
.form-radio-error,
.form-select-error {
  @apply border-error-500 focus:border-error-500;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @apply border-black;
  -webkit-text-fill-color: theme('colors.black');
  -webkit-box-shadow: 0 0 0px 1000px theme('colors.gray.100') inset;
}

.form-input-icon {
  @apply absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4;
}
