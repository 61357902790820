html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

svg {
  width: 100%;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

button {
  letter-spacing: inherit;
}

img[ci-src] {
  opacity: 0;
}

b,
strong {
  @apply font-medium;
}
