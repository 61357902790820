@use '@angular/material' as mat;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@include mat.core();

$mat-gold: (
  100: var(--base-gold-100),
  200: var(--base-gold-200),
  300: var(--base-gold-300),
  500: var(--base-gold-500),
  700: var(--base-gold-700),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$pluscity-cockpit-primary: mat.define-palette($mat-gold);
$pluscity-cockpit-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$pluscity-cockpit-warn: mat.define-palette(mat.$red-palette);

$custom-typography: mat.define-typography-config(
  $font-family: 'EuclidFlex, sans-serif',
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$pluscity-cockpit-theme: mat.define-light-theme(
  (
    color: (
      primary: $pluscity-cockpit-primary,
      accent: $pluscity-cockpit-accent,
      warn: $pluscity-cockpit-warn,
    ),
    typography: $custom-typography,
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($pluscity-cockpit-theme);

@include mat.select-theme($pluscity-cockpit-theme);
@include mat.dialog-theme($pluscity-cockpit-theme);
@include mat.datepicker-theme($pluscity-cockpit-theme);
@include mat.autocomplete-theme($pluscity-cockpit-theme);
/* You can add global styles to this file, and also import other style files */
