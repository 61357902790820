@font-face {
  font-family: 'EuclidFlex';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../fonts/euclid-flex-regular.woff') format('woff');
}

@font-face {
  font-family: 'EuclidFlex';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../fonts/euclid-flex-medium.woff') format('woff');
}
