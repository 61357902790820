@layer components {
  .typo-marge {
    @apply text-5xl font-medium;
    /* @extend .fl-text-5xl; */
    &.typo-marge--mobile-preview {
      font-size: 2.416667rem;
    }
  }

  .typo-d-marge {
    font-size: 2.416667rem;
    @apply font-medium md:text-4xl xl:text-5xl;
  }

  .typo-homer {
    @apply text-4xl font-medium;
    /* @extend .fl-text-4xl; */
  }

  .typo-d-homer {
    @apply text-3xl font-medium md:text-4xl;
  }

  .typo-lovejoy {
    @apply text-3xl font-medium;
    /* @extend .fl-text-3xl; */
  }

  .typo-d-lovejoy-important {
    @apply text-2xl font-medium md:text-3xl #{!important};
  }

  .typo-apu {
    @apply text-2xl font-normal uppercase text-gold-500;
    /* @extend .fl-text-2xl; */
  }

  .typo-moe {
    @apply text-2xl;
  }

  .typo-skinner {
    @apply text-xl font-medium;
  }

  .typo-edna {
    @apply text-xl font-normal;
  }

  .typo-d-edna {
    @apply text-lg font-normal md:text-xl;
  }

  .typo-manjula {
    @apply text-xl font-normal uppercase text-gold-500;
  }

  .typo-lenny {
    @apply text-lg font-normal;
  }

  .typo-bumblebee {
    @apply text-lg font-normal uppercase text-gold-500;
  }

  .typo-otto {
    @apply text-base font-normal;
  }

  .typo-patty {
    @apply text-base font-medium;
  }

  .typo-bart {
    @apply text-sm font-normal;
  }

  .typo-ralph {
    @apply text-sm font-medium;
  }

  .typo-lisa {
    @apply text-xs font-normal;
  }

  .typo-todd {
    @apply text-xs font-medium;
  }

  .typo-maggie {
    @apply text-3xs font-normal;
  }
}
