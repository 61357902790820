%bg-gradient {
  @apply relative z-0 overflow-hidden;

  &:before,
  &:after {
    content: '';
    @apply absolute inset-0;
  }

  &:before {
    @apply -z-10 transition-opacity duration-200;
  }

  &:after {
    @apply -z-20;
  }
}

%bg-primary-gradient {
  @extend %bg-gradient;

  &:before {
    @apply bg-gradient-to-t from-gold-700 to-gold-500;
  }

  &:after {
    @apply bg-gold-700;
  }
}

%bg-secondary-gradient {
  @extend %bg-gradient;

  &:before {
    @apply bg-gradient-to-t from-black to-gray-600;
  }

  &:after {
    @apply bg-black;
  }
}

.bg-primary-gradient {
  @extend %bg-primary-gradient;
}

.bg-secondary-gradient {
  @extend %bg-secondary-gradient;
}
