/*.introjs-tooltip {
  @apply text-black;
}

.introjs-button-custom {
  @apply inline-flex items-center justify-center rounded-md typo-patty whitespace-nowrap  text-white px-6 py-3 md:py-2 cursor-pointer hover-hover:hover:before:opacity-0 transition duration-200 border-none;
  @extend %bg-primary-gradient;

  text-shadow: none;

  &.introjs-disabled {
    @apply text-white cursor-default opacity-50 hover-hover:hover:before:opacity-100;
    &:hover {
      @apply text-white;
    }
  }
}

.introjs-tooltipReferenceLayer {
  &,
  * {
    font-family: inherit !important;
  }
}

.introjs-tooltip-title {
  font-weight: 500 !important;
}
*/
.introjs-tooltip {
  display: none !important;
}

.introjs-helperLayer {
  box-shadow: none !important;
  &:after {
    box-shadow: rgba(0, 0, 0, $backdrop-opacity) 0px 0px 0px 5000px;
    @apply absolute inset-0 -m-4 block rounded border-2 border-gold-500 content-[''];
  }
}
