/*******
* Font-size
*******/

$font-base: 'EuclidFlex';

$text-3xs: 0.6875rem;
$text-xs: 0.8125rem;
$text-sm: 0.875rem;
$text-base: 1rem;
$text-lg: 1.1875rem;
$text-xl: 1.375rem;
$text-2xl: 1.625rem;
$text-3xl: 1.875rem;
$text-4xl: 3rem;
$text-5xl: 3.625rem;

/*******
* Breakpoints
*******/

$screen-xs: 480px;
$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;
$screen-2xl: 1536px;

$backdrop-opacity: 0.5;
